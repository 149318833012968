.gallery {
    margin: 0 auto;
    text-align: center;

    a {
        display: inline-block;
        position: relative;
        margin: 5px;
        width: 200px;
        height: 160px;
        border: 1px solid #123123;
        border-radius: 4px;
        text-align: center;
        vertical-align: middle;
        overflow: hidden;
        transition: border 400ms ease, box-shadow 400ms ease;

        &:hover {
            border: 1px solid #FE7503;
            box-shadow: 0 0 6px 2px #FE7503;
            transition: border 400ms ease, box-shadow 400ms ease;
        }

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 100%;
            max-height: 100%;
            vertical-align: middle;
        }

        .description {
            position: absolute;
            bottom: 0;
            /*padding: 10px 0;*/
            padding: 0;
            width: 200px;
            text-align: center;
            color: #123123;

            p {
                margin: 0;
                padding: 0 6px;
                background-color: rgba(255, 255, 255, 0.9);
                /*box-shadow: 0 0 8px 10px rgba(255, 255, 255, 0.9);*/
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }
}
