#navigation {
    ul {
        position: absolute;
        top: 25px; left: 5px;
        margin: 10px 0 0 5px;
        padding: 0 0 40px 0;
        width: 172px;
        text-align: left;
        @include user-select(none);

        ul {
            display: none;
            position: absolute;
            top: -1px; left: 100%;
            z-index: 1;

            margin: 0 0 0 1px;
        }

        li {
            list-style: none;
            position: relative;

            a {
                display: block;
                margin: 0;
                padding: 0.05em 0 0 0.5em;

                font-family: Arial, Helvetica, sans-serif;
                line-height: 140%;
                font-size: 14px;
                font-weight: normal;
                text-decoration: none;
                transition: all 0.3s ease;
            }

            ul li {
                width: 16em;
            }

            &.sub {
                display: block;
                position: relative;
                margin: 0;
                padding: 0.05em 0.6em 0 0.5em;

                font-family: Arial, Helvetica, sans-serif;
                line-height: 140%;
                font-size: 14px;
                font-weight: normal;
                text-decoration: none;
                cursor: default;

                &::after {
                    $caretWidth: 5px;
                    border-bottom: $caretWidth solid transparent;
                    border-top: $caretWidth solid transparent;
                    border-left: $caretWidth solid currentColor;

                    content: '';
                    display: inline-block;
                    position: absolute;
                    right: 4px;
                    top: calc(50% - #{$caretWidth});
                    width: 0;
                    height: 0;
                    vertical-align: top;
                }

                &:hover ul {
                    display: block;
                }
            }

            &.spacer {
                margin-bottom: 0.3em;
            }
            &.spacer_big {
                margin-bottom: 1em;
            }
            &.sub li:first-child a:hover  {
                border-left: 0 none;
            }
        }
    }

    /* Menü Farben */
    ul li {
        &.orange_sub,
        &.orange a,
        &.orange a:link,
        &.orange a:visited {
            color: #663800;
            background-color: #FF9900;
            border: 1px outset #FF9900;
        }

        &.orange_sub:hover,
        &.orange a:hover {
            color: #AF6900;
            background-color: #F8C880;
            border: 1px inset #FF9900;
        }

        &.orange_light_sub,
        &.orange_light a,
        &.orange_light a:link,
        &.orange_light a:visited {
            color: #663800;
            background-color: #FF9966;
            border: 1px outset #FF9966;
        }

        &.orange_light_sub:hover,
        &.orange_light a:hover {
            color: #AA63AA;
            background-color: #FFCAFF;
            border: 1px inset #AA63AA;
        }

        &.yellow_sub,
        &.yellow a,
        &.yellow a:link,
        &.yellow a:visited {
            color: #663800;
            background-color: #FFB404;
            border: 1px outset #FFB404;
        }

        &.yellow_sub:hover,
        &.yellow a:hover {
            color: #AA7802;
            background-color: #FFD779;
            border: 1px inset #AA7802;
        }

        &.yellow2_sub,
        &.yellow2 a,
        &.yellow2 a:link,
        &.yellow2 a:visited {
            color: rgb(100, 58, 2);
            background-color: rgb(254,217,5);
            border: 1px outset #FED905;
        }

        &.yellow2_sub:hover,
        &.yellow2 a:hover {
            color: rgb(103, 70, 37);
            background-color: rgb(254,251,137);
            border: 1px inset #FED905;
        }

        &.blue_dark_sub,
        &.blue_dark a,
        &.blue_dark a:link,
        &.blue_dark a:visited {
            color: #FFFFFF;
            background-color: #0066FF;
            border: 1px outset #0066FF;
        }

        &.blue_dark_sub:hover,
        &.blue_dark a:hover {
            color: #0046AF;
            background-color: #78ADFF;
            border: 1px inset #0046AF;
        }

        &.blue_light_sub,
        &.blue_light a,
        &.blue_light a:link,
        &.blue_light a:visited {
            color: #FFFFFF;
            background-color: #428BF9;
            border: 1px outset #428BF9;
        }

        &.blue_light_sub:hover,
        &.blue_light a:hover {
            color: #2E5FAB;
            background-color: #98BDED;
            border: 1px inset #2E5FAB;
        }

        &.cyan_sub,
        &.cyan a,
        &.cyan a:link,
        &.cyan a:visited {
            color: rgb(22, 71, 37);
            background-color: #29CB97;
            border: 1px outset #29CB97;
        }

        &.cyan_sub:hover,
        &.cyan a:hover {
            color: #1C8B67;
            background-color: #94E5CB;
            border: 1px inset #1C8B67;
        }

        &.cyan2_sub,
        &.cyan2 a,
        &.cyan2 a:link,
        &.cyan2 a:visited {
            color: rgb(22, 71, 37);
            background-color: rgb(14, 239, 104);
            border: 1px outset rgb(14, 239, 104);
        }

        &.cyan2_sub:hover,
        &.cyan2 a:hover {
            color: rgb(47, 147, 77);
            background-color: rgb(154, 241, 181);
            border: 1px inset rgb(154, 241, 181);
        }

        &.green_dark_sub,
        &.green_dark a,
        &.green_dark a:link,
        &.green_dark a:visited {
            color: #FFFFFF;
            background-color: #336600;
            border: 1px outset #336600;
        }

        &.green_dark_sub:hover,
        &.green_dark a:hover {
            color: #244800;
            background-color: #99B27F;
            border: 1px inset #244800;
        }

        &.green_light_sub,
        &.green_light a,
        &.green_light a:link,
        &.green_light a:visited {
            color: #FFFFFF;
            background-color: #669900;
            border: 1px outset #669900;
        }

        &.green_light_sub:hover,
        &.green_light a:hover {
            color: #446600;
            background-color: #ABC678;
            border: 1px inset #446600;
        }

        &.violet_dark_sub,
        &.violet_dark a,
        &.violet_dark a:link,
        &.violet_dark a:visited {
            color: #FFFFFF;
            background-color: #BA07F5;
            border: 1px outset #BA07F5;
        }

        &.violet_dark_sub:hover,
        &.violet_dark a:hover {
            color: #820CA8;
            background-color: #D67BF5;
            border: 1px inset #820CA8;
        }

        &.violet_light_sub,
        &.violet_light a,
        &.violet_light a:link,
        &.violet_light a:visited {
            color: #FFFFFF;
            background-color: #AC7CE0;
            border: 1px outset #AC7CE0;
        }

        &.violet_light_sub:hover,
        &.violet_light a:hover {
            color: #75549A;
            background-color: #D5BDEF;
            border: 1px inset #75549A;
        }

        &.pink_sub,
        &.pink a,
        &.pink a:link,
        &.pink a:visited {
            color: #FFFFFF;
            background-color: #FF0066;
            border: 1px outset #FF0066;
        }

        &.pink_sub:hover,
        &.pink a:hover {
            color: #AA0044;
            background-color: #FF7FB2;
            border: 1px inset #AA0044;
        }

        &.brown_sub,
        &.brown a,
        &.brown a:link,
        &.brown a:visited {
            color: #FFFFFF;
            background-color: #CC6600;
            border: 1px outset #CC6600;
        }

        &.brown_sub:hover,
        &.brown a:hover {
            color: #8C4600;
            background-color: #E5B27F;
            border: 1px inset #8C4600;
        }

        &.grey_light_sub,
        &.grey_light a,
        &.grey_light a:link,
        &.grey_light a:visited {
            color: rgb(50, 50, 50);
            background-color: rgb(239, 239, 239);
            border: 1px outset rgb(239, 239, 239);
        }

        &.grey_light_sub:hover,
        &.grey_light a:hover {
            color: rgb(100, 100, 100);
            background-color: rgb(247, 247, 247);
            border: 1px inset rgb(247, 247, 247);
        }
    }

    /* Suche */
    ul {
        .search {
            padding: 1px 0;
            cursor: default;
        }
        li.search.orange_sub:hover,
        li.search.orange a:hover {
            color: #663800;
            background-color: #FF9900;
            border: 1px outset #FF9900;
        }
        .search {
            form {
                display: inline-block;
                margin: 0;
                padding: 0;
                white-space: nowrap;
                width: 100%;
            }
            label {
                margin-left: 4px;
                margin-bottom: 0;
                vertical-align: top;
            }
            input {
                display: inline-block;
                max-width: 100%;
                font-size: 0.7em;
                vertical-align: top;
            }
            .fas {
                margin-left: 5px;
                vertical-align: middle;
            }
        }
    }
}

/* Menü Translation*/
.menu_translation li a {
    img {
        height: 13px;
    }
    &:hover img {
        margin-left: 1px;
    }
}
