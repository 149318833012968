html, body {
    margin: 0;
    padding: 0;
}
body {
    padding-left: 222px;
    padding-right: 40px;
    font-family: Geneva, Arial, Helvetica, sans-serif;
}

/* Header */
.header {
    margin: 0 auto;
    padding-top: 30px;
    text-align: center;
}

.header a.logo {
    display: block;
    margin: 0 auto 30px auto;
    width: 458px;
    height: 53px;
    background-image: url(../../public/images/logo/tanz-der-farben.gif);
}

/* Content */
.content {
    margin: 0 auto;
    text-align: center;
}

/* Footer */
.footer-website-updated {
    font-size: 13px;
    color: #00FF66;
}
.tx-cybTools-date {
    font-weight: bold;
    color: white;
}
.tx-cybTools-date .word {
    color: yellow;
}

/* Tanz der Farben - Farbig */
.tanz_der_farben b:nth-of-type(1) {color: #990099;}
.tanz_der_farben b:nth-of-type(2) {color: #00CC66;}
.tanz_der_farben b:nth-of-type(3) {color: #FF3300;}
.tanz_der_farben b:nth-of-type(4) {color: #CC00CC;}

.tanz_der_farben b:nth-of-type(5) {color: #6633FF;}
.tanz_der_farben b:nth-of-type(6) {color: #6F9300;}
.tanz_der_farben b:nth-of-type(7) {color: #FF0066;}

.tanz_der_farben b:nth-of-type(8) {color: #FF6666;}
.tanz_der_farben b:nth-of-type(9) {color: #669933;}
.tanz_der_farben b:nth-of-type(10) {color: #FF3366;}
.tanz_der_farben b:nth-of-type(11) {color: #3333CC;}
.tanz_der_farben b:nth-of-type(12) {color: #CC0099;}
.tanz_der_farben b:nth-of-type(13) {color: #FF9933;}


/* Frohkostgipfel - Farbig */
.colortext-frohkostgipfel {
    font-family: Geneva, Arial, Helvetica, sans-serif;
    font-style: italic;
    text-transform: uppercase;
}
.colortext-frohkostgipfel b:nth-of-type(1) {color: #FF0099;}
.colortext-frohkostgipfel b:nth-of-type(2) {color: #FF3333;}
.colortext-frohkostgipfel b:nth-of-type(3) {color: #FF9900;}
.colortext-frohkostgipfel b:nth-of-type(4) {color: #FFCC00;}
.colortext-frohkostgipfel b:nth-of-type(5) {color: #FFFF00;}
.colortext-frohkostgipfel b:nth-of-type(6) {color: #FFFFFF;}
.colortext-frohkostgipfel b:nth-of-type(7) {color: #FFFF00;}
.colortext-frohkostgipfel b:nth-of-type(8) {color: #FFCC00;}
.colortext-frohkostgipfel b:nth-of-type(9) {color: #FF9900;}
.colortext-frohkostgipfel b:nth-of-type(10) {color: #FF3333;}
.colortext-frohkostgipfel b:nth-of-type(11) {color: #FF0099;}
.colortext-frohkostgipfel b:nth-of-type(12) {color: #FF0099;}

/* Altes Design */
div.content {
    width: 1024px;
    margin: 0 auto;
}

div.footer {
    margin: 0 auto 30px auto;
    padding: 3px;

    font-size: 12px;
    font-family: Arial, Verdana, Geneva, sans-serif;
    line-height: 1.5em;
    text-align: center;

    color: #000000;
    background-color: #FDF1B0;
}

div.clear {
    clear: both;
}
div.center {
    margin: 0 auto;
    text-align: center;
}

/* Bootstrap methods */
.text-center {
    text-align: center;
}

/* Bootstrap pagination */
.pagination {
    margin: 0;
    padding-left: 0;
    border-radius: 4px;
    justify-content: center;

    > li {
        display: inline;

        > li:first-child {
            > a,
            > span {
                margin-left: 0;
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
            }
        }
        > li:last-child {
            > a,
            > span {
                margin-left: 0;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
            }
        }
    }
    > li {
        > a,
        > span {
            position: relative;
            float: left;
            padding: 6px 12px;
            margin-left: -1px;
            line-height: 1.42857143;
            color: #337ab7;
            text-decoration: none;
            background-color: #fff;
            border: 1px solid #ddd;
        }
    }
    > .active {
        > a,
        > a:focus,
        > a:hover,
        > span,
        > span:focus,
        > span:hover {
            z-index: 3;
            color: #fff;
            cursor: default;
            background-color: #337ab7;
            border-color: #337ab7;
        }
    }
    > li {
        > a:focus,
        > a:hover,
        > span:focus,
        > span:hover {
            z-index: 2;
            color: #23527c;
            background-color: #eee;
            border-color: #ddd;
        }
    }
}

/* Custom */
#google_translate_element {
    position: absolute;
    top: 5px;
    left: 10px;
}

/* Error page */
.talk-error {
    background-image: url(../../public/images/hintergruende/spi-grau2.jpg);
    background-color: #C6BDBD;

    h1 {
        color: #FFFFFF;
    }

    .error-image {
        width: 96%;
        border: 2px inset #996699;
    }

    .error-message {
        margin: 20px auto;
        width: 96%;

        background-color: #FFFFFF;
        border: 2px inset #996699;

        p {
            margin: 10px;
            text-align: left;
            color: #173A76;
        }
        span {
            color: #0C8460;
        }
    }
}
